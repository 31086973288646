<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="60%"
    top="2vh"
    @close="onDialogClose">
    <div :v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="fileManage"
        :rules="ruleValidate">
        <el-form-item label="名称">
          <el-input
            v-model="fileManage.name"
            :placeholder="$t('common.pleaseEnter')"
            auto-complete="off"></el-input>
        </el-form-item>
        <div class="vm-separate">
          <el-form-item label="选择文件" prop="userType">
            <el-select v-model="currentFileName" filterable style="width: 100%">
              <el-option
                v-for="item in panList"
                :key="item.id"
                :label="item.name"
                :value="item.id"
                @click.native="selectPan(item)"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="状态">
            <el-select v-model="fileManage.status" style="width: 100%">
              <el-option label="有效" :value="0"></el-option>
              <el-option label="作废" :value="1"></el-option>
            </el-select>
          </el-form-item>
        </div>
        <el-table
          :data="fileManage.fileManageDetailVOS"
          border
          stripe
          style="width: 100%">
          <table-column prop="fileName" label="文件名称">
          </table-column>
          <table-column :label="$t('common.operation')" :width="200" align="center" :tooltip="false">
            <template #default="scope">
              <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                {{$t("common.remove")}}
              </el-button>
            </template>
          </table-column>
        </el-table>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  // import FaultSelect from "../fault/FaultSelect.vue";
  import VmUploadFile from "@/components/VmUploadFile";

  const moduleName = "fileManages";

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        filter:"",
        currentFileName:"",
        fileManage: {
          id: 0,
          status: 0,
          qrCode: window.config.SERVER_URL,
          name: "",
          fileManageDetailVOS: [],
        },
        panList:[],
        ruleValidate: {},
      };
    },
    computed: {
      title() {
        return this.fileManage.id === 0 ? this.$t("common.add") : this.$t("common.edit");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.fileManage.id = id;
        if (id > 0) {
          this.getData();
        }
        this.getPan();
      },
      deleteRow(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.fileManage.fileManageDetailVOS.splice(index, 1);
        });
      },
      getData() {
        this.$http.get("fileManages/" + this.fileManage.id).then(res => {
          console.log(res);
          this.fileManage = Object.assign(this.fileManage, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getPan() {
        let parme = {
          filter:this.filter
        };
        this.$http.get("pans/getAllList",parme).then(res => {
          this.panList = res.data;
        }).catch(() => {

        });
      },
      selectPan(item) {
        console.log(item);
        let params = {
          fileName:item.name,
          fileId:item.id,
        };
        this.fileManage.fileManageDetailVOS.push(params);
        console.log(this.fileManage.fileManageDetailVOS);
      },
      onDialogClose() {
        this.fileManage.status = "";
        this.fileManage.id = 0;
        this.fileManage.qrCode = "";
        this.fileManage.name = "";
        this.fileManage.fileManageDetailVOS = [];
      },
      handleSubmit() {
        console.log(this.fileManage);
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("fileManages", this.fileManage).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.fileManage.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
